<template>
  <div>
    <comment-list-top-nav />
    <v-data-table
      :items="commentList"
      class="elevation-1 table-vertical-align-top"
      :pagination.sync="pagination"
      :loading="isLoading"
      :total-items="total" 
      :rows-per-page-items="[10, 25, 50, 100]"
      :headers-length="4"
    >
      <template slot="items" slot-scope="props">
        <td class="custom-td">
          <table class="v-datatable v-table theme--dark">
            <tr class="custom-tr-bt">
              <td class="first-col">
                <a
                  :href="`${BaseUrl}post/${props.item.post.id}`"
                  target="_blank"
                >
                  <div v-if="props.item.contents && props.item.contents.length">
                    <div
                      v-if="props.item.contents[0].type === 'iframe'"
                      class="mb-3"
                    >
                      <video :poster="props.item.contents.preview">
                        <source
                          v-if="props.item.contents.web_url"
                          :src="props.item.contents.web_url"
                          type="video/webm"
                        >
                        <source
                          :src="props.item.contents.url"
                          type="video/mp4"
                        >
                      </video>
                    </div>
                    <div
                      v-else-if="props.item.contents[0].type === 'image'"
                      class="mb-3"
                    >
                      <img :src="props.item.contents[0].url" alt="">
                    </div>

                    <div class="font-size-12">
                      {{ props.item.comment_content_name }}
                    </div>
                  </div>
                  <div class="font-size-11 mb-3 text-word">
                    {{ props.item.post.title }}
                  </div>
                  <div
                    v-if="props.item.reply_to"
                    class="font-size-11 mb-3 text-word"
                  >
                    Reply to: {{ props.item.reply_to.username }}
                  </div>
                </a>
              </td>
              <td>
                <v-layout :class="{ 'answer-item': props.item.answer }" row>
                  <v-flex>
                    <v-avatar size="60px">
                      <router-link
                        class="avatar-link"
                        :to="`/moderation/user_list/user/${props.item.user_id}`"
                      >
                        <img
                          v-if="props.item.user && props.item.user.image"
                          class="user-icon"
                          :src="props.item.user.image"
                          alt=""
                        >
                        <v-icon v-else class="user-icon">
                          mdi-account
                        </v-icon>
                      </router-link>
                    </v-avatar>
                  </v-flex>
                  <v-flex xs11>
                    <div v-if="!props.item.selected">
                      <v-layout row justify-space-between align-end>
                        <v-flex class="username-block">
                          <router-link
                            :to="
                              `/moderation/user_list/user/${props.item.user_id}`
                            "
                          >
                            <div class="headline text--darken-1">
                              {{
                                props.item.is_user_banned
                                  ? "Deleted user"
                                  : props.item.username
                              }}
                            </div>
                          </router-link>
                        </v-flex>
                        <v-flex class="text-xs-right">
                          {{ timePassed(props.item.created_at) }}
                        </v-flex>
                      </v-layout>

                      <div class="comment-text-block">
                        {{ props.item.comment }}
                      </div>

                      <div class="mt-3">
                        <span class="font-size-15 mr-4">
                          <v-icon class="blue-grey--text mr-2">thumb_up</v-icon>
                          <b class="teal--text">{{
                            props.item.count_like_up_votes
                          }}</b>
                        </span>
                        <span class="font-size-15">
                          <v-icon class="blue-grey--text mr-2">thumb_down</v-icon>
                          <b class="red--text">{{
                            props.item.count_like_down_votes
                          }}</b>
                        </span>
                      </div>
                      <div
                        v-if="props.item.comment_content.length > 0"
                        class="mt-3 swiper-own-style"
                      >
                        <swiper :options="swiperOption" class="photo-swiper">
                          <swiper-slide
                            v-for="(img, imageIndex) in props.item
                              .comment_content"
                            :key="img.id"
                            class="slide-1"
                          >
                            <div class="photo-list-img-block ">
                              <div class="img-wrap">
                                <img
                                  :src="img.url"
                                  class="photo-list-img"
                                  alt="avatar"
                                  @click="
                                    setImage(
                                      imageIndex,
                                      props.item.comment_content
                                    )
                                  "
                                >
                              </div>
                            </div>
                          </swiper-slide>
                          <div slot="button-prev" class="swiper-button-prev"></div>
                          <div slot="button-next" class="swiper-button-next"></div>
                        </swiper>
                      </div>
                    </div>

                    <div v-else>
                      <v-layout row justify-space-between align-end>
                        <v-flex>
                          <div class="headline text--darken-1">
                            {{ props.item.name }}
                          </div>
                        </v-flex>
                      </v-layout>
                      <div class="display-1 red--text text--darken-1">
                        Comment has been deleted
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </td>
              <td class="btn-block">
                <div v-if="!props.item.selected">
                  <!--                        <v-btn block-->
                  <!--                               color="success"-->
                  <!--                               class="ma-0 mb-3"-->
                  <!--                               @click="editItem(props.item)"-->
                  <!--                        >-->
                  <!--                            Edit-->
                  <!--                        </v-btn>-->
                  <v-btn
                    block
                    color="error"
                    class="ma-0"
                    @click="deleteItem(props.item, props.item.id)"
                  >
                    Delete
                  </v-btn>
                </div>
              </td>
            </tr>
            <!--            <template v-if="props.item.comments && props.item.comments.length">-->
            <!--              <tr-->
            <!--                class="custom-tr"-->
            <!--                v-for="reply in props.item.comments"-->
            <!--                :key="reply.id"-->
            <!--              >-->
            <!--                <td></td>-->
            <!--                <td>-->
            <!--                  <v-layout row>-->
            <!--                    <v-flex>-->
            <!--                      <v-avatar size="60px">-->
            <!--                        <router-link-->
            <!--                          :to="`/moderation/user_list/user/${reply.user_id}`"-->
            <!--                        >-->
            <!--                          <img-->
            <!--                            class="user-icon"-->
            <!--                            v-if="reply.user && reply.user.image"-->
            <!--                            :src="reply.user.image"-->
            <!--                            alt=""-->
            <!--                          />-->
            <!--                          <v-icon class="user-icon" v-else>mdi-account</v-icon>-->
            <!--                        </router-link>-->
            <!--                      </v-avatar>-->
            <!--                    </v-flex>-->
            <!--                    <v-flex xs11>-->
            <!--                      <div v-if="!reply.comments.selected">-->
            <!--                        <v-layout row justify-space-between align-end>-->
            <!--                          <v-flex class="username-block">-->
            <!--                            <router-link-->
            <!--                              :to="-->
            <!--                                `/moderation/user_list/user/${reply.user_id}`-->
            <!--                              "-->
            <!--                            >-->
            <!--                              <div class="headline text&#45;&#45;darken-1">-->
            <!--                                {{ reply.username }}-->
            <!--                              </div>-->
            <!--                            </router-link>-->
            <!--                          </v-flex>-->
            <!--                          <v-flex class="text-xs-right">-->
            <!--                            {{ timePassed(reply.created_at) }}-->
            <!--                          </v-flex>-->
            <!--                        </v-layout>-->

            <!--                        <div class="comment-text-block">-->
            <!--                          {{ reply.comment }}-->
            <!--                        </div>-->

            <!--                        <div class="mt-3">-->
            <!--                          <span class="font-size-15 mr-4">-->
            <!--                            <v-icon class="blue-grey&#45;&#45;text mr-2"-->
            <!--                              >thumb_up</v-icon-->
            <!--                            >-->
            <!--                            <b class="teal&#45;&#45;text">{{-->
            <!--                              reply.count_like_up_votes-->
            <!--                                ? reply.count_like_up_votes-->
            <!--                                : 0-->
            <!--                            }}</b>-->
            <!--                          </span>-->
            <!--                          <span class="font-size-15">-->
            <!--                            <v-icon class="blue-grey&#45;&#45;text mr-2"-->
            <!--                              >thumb_down</v-icon-->
            <!--                            >-->
            <!--                            <b class="red&#45;&#45;text">{{-->
            <!--                              reply.count_like_down_votes-->
            <!--                                ? reply.count_like_down_votes-->
            <!--                                : 0-->
            <!--                            }}</b>-->
            <!--                          </span>-->
            <!--                        </div>-->
            <!--                      </div>-->

            <!--                      <div v-else>-->
            <!--                        <v-layout row justify-space-between align-end>-->
            <!--                          <v-flex>-->
            <!--                            <div class="headline text&#45;&#45;darken-1">-->
            <!--                              {{ reply.name }}-->
            <!--                            </div>-->
            <!--                          </v-flex>-->
            <!--                        </v-layout>-->
            <!--                        <div class="display-1 red&#45;&#45;text text&#45;&#45;darken-1">-->
            <!--                          Comment has been deleted-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </v-flex>-->
            <!--                  </v-layout>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <div v-if="!reply.selected">-->
            <!--                    <v-btn-->
            <!--                      block-->
            <!--                      color="error"-->
            <!--                      class="ma-0"-->
            <!--                      @click="deleteItem(props.item, reply.id)"-->
            <!--                    >-->
            <!--                      Delete-->
            <!--                    </v-btn>-->
            <!--                  </div>-->
            <!--                </td>-->
            <!--              </tr>-->
            <!--            </template>-->
          </table>
        </td>
      </template>
    </v-data-table>

    <v-layout row align-center>
      <v-flex xs11>
        <div class="text-xs-center mt-4">
          <div class="text-xs-center">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              @input="refreshPagination"
            />
          </div>
        </div>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog" max-width="750px">
      <v-card>
        <v-card-title>
          <v-layout align-center justify-space-between>
            <span class="headline">{{ formTitle }}</span>

            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>

        <v-card-text>
          <div>
            <div class="mb-2 grey--text">
              Please pick the reason why you have blocked this user
            </div>
          </div>

          <div>
            <v-textarea
              v-model="editedItem.text"
              solo
              box
              rows="12"
              name="input-7-4"
              value=""
            />
          </div>
        </v-card-text>

        <v-card-actions class="px-3 pt-0">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            Cancel
          </v-btn>
          <v-btn color="success" @click.native="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <gallery
      :id="galleryId"
      :key="galleryKey"
      :images="images"
      :index="index"
      @close="index = null"
    />
  </div>
</template>

<script>
import { BaseUrl } from "../../../../../config"
import { getAllPublishedComments } from "../../../../../api/publishedComments"
import { deletePublishedComments } from "../../../../../api/publishedCommentDelete"
import CommentListTopNav from "../CommentListTopNav"
import { fetchData } from "../../../../../utils/response"
import SavesPagination from "../../../../../mixins/SavesPaginationMixin"
import { log } from "../../../../../utils/helpers"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import gallery from "vue-gallery"
import "swiper/css/swiper.css"
import moment from "moment"
export default {
  name: "CommentList",
  components: {
    CommentListTopNav,
    SwiperSlide,
    gallery,
    Swiper
  },
  mixins: [SavesPagination],
  data() {
    return {
      formTitle: "Edit comment",
      dialog: false,
      editedIndex: -1,
      commentList: [],
      selected: [],
      select: null,
      search: null,
      BaseUrl: "",
      total: 0,
      galleryId: "gallery-4",
      galleryKey: "gallery-4",
      isLoading: false,
      index: null,
      images: [],
      pagination: {},
      editedItem: {
        text: ""
      },
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  created() {
    this.$bus.on("user-sort-by", this.onSelectUsersBy)
    this.$bus.on("comment-search-input", this.onSearchUser)
  },
  mounted() {
    this.BaseUrl = BaseUrl
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  destroyed() {
    this.search = null
    this.$bus.off("user-sort-by", this.onSelectUsersBy)
    this.$bus.off("comment-search-input", this.onSearchUser)
  },
  methods: {
    timePassed(create) {
      return moment(create).fromNow()
    },
    setImage(index, arr) {
      this.index = index
      const imageArr = []
      arr.forEach(item => {
        imageArr.push(item.url)
      })
      this.images = imageArr
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        order_by: this.select,
        search: this.search
      }
      this.isLoading = true
      this.commentList = await getAllPublishedComments(params)
        .then(response => {
          this.total = response.data.meta.total
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(comment => {
            return comment
          })
        )
        .catch(this.$notificationHandleResponseError)

      this.isLoading = false
    },
    editItem(item) {
      this.editedIndex = this.CommentList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.CommentList[this.editedIndex], this.editedItem)
      } else {
        this.CommentList.push(this.editedItem)
      }
      this.close()
    },
    async deleteItem(item, id) {
      await deletePublishedComments(id)
        .then(response => {
          log(response)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
      this.$set(item, "selected", !item.selected)
    },
    onSelectUsersBy(sortUserSelectValue) {
      this.select = sortUserSelectValue
      this.refresh()
    },
    onSearchUser(searchUserValue) {
      this.search = searchUserValue
      this.pagination.page = 1
      this.refreshPagination()
    }
  }
}
</script>

<style scoped>
.text-word {
  word-break: break-word;
}
.swiper-own-style {
  width: 100%;
  max-width: 800px;
  height: 200px;
}
.swiper-own-style img {
  max-height: 200px;
}
.username-block {
  max-width: 82%;
  word-break: break-word;
}
.first-col {
  width: 200px;
}

.first-col img,
.first-col video {
  max-width: 150px;
}

.table-vertical-align-top td {
  vertical-align: top;
}

.btn-block {
  width: 90px;
}

.center-block {
  width: 100%;
}

.highlight {
  background: yellow;
}

.answer-item {
  padding-left: 95px;
}
.avatar-link {
  text-decoration: none;
  display: block;
  width: 60px;
  height: 60px;
}
.user-icon {
  font-size: 54px;
  max-width: 100%;
  width: 100%;
}
.custom-td {
  padding: 24px 0 !important;
}
.custom-tr > td {
  padding-top: 24px !important;
}
.custom-tr-bt > td {
  padding-bottom: 24px !important;
}
.comment-text-block {
  word-break: break-word;
}
</style>
