import api from "./index"

/**
 * Get all publishedComments
 *
 * @param params
 * @param includes 
 * @return {*}
 */

export const getAllPublishedComments = params => {
  return api
    .request("admin/comments/publish")
    .withParams(params)
    .get()
}
