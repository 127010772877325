import api from "./index"

/**
 * Get all publishedComments
 *
 * @param params
 * @param includes 
 * @return {*}
 */

export const deletePublishedComments = params => {
  return api
    .request(`admin/remove-comment/${params}`)
    .withParams(params)
    .delete()
}
